import { Grid, Slider, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectActiveVariant, selectGivenAnswer,
  selectShowSmileys, setGivenAnswer, setQuestionSliderIsDragging
} from "../../features/questionnaire/questionnaireSlice";
import { useTranslation } from "react-i18next";

const DefaultAnswerOption = () => {

  const showSmileys = useAppSelector(selectShowSmileys);
  const variant = useAppSelector(selectActiveVariant);
  const givenAnswer = useAppSelector(selectGivenAnswer);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [marks, setMarks] = useState<{ value: number, label?: React.ReactNode }[]>();

  const handleSliderChange = (e: Event, newValue: number | number[]) => {
    dispatch(setGivenAnswer(+newValue));
  }

  function preventKeyboardArrowSlide(event: React.KeyboardEvent) {
    if (['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'].includes(event.key)) {
      event.preventDefault();
    }
  }

  useEffect(() => {
    if (variant === "adults-en") {
      i18n.changeLanguage("en")
    } else if (variant?.endsWith("-de")) {
      i18n.changeLanguage("de")
    } else if (variant?.endsWith("-fy")) {
      i18n.changeLanguage("fy")
    } else {
      i18n.changeLanguage("nl")
    }
  }, [i18n, variant])

  useEffect(() => {
    const agreeText = variant === "simple" ? "" : t('Completely Agree');
    const disagreeText = variant === "simple" ? "" : t('Completely Disagree');

    setMarks(showSmileys ? [
      {
        value: 0,
        label: (
          <Stack alignItems="center">
            <Typography variant="h2">😧</Typography>
            <Typography className="bottom" variant="h6" sx={{ fontSize: { xs: '0.5rem', sm: '0.75rem', md: '1rem', lg: '1.26rem' } }}>{disagreeText}</Typography>
          </Stack>
        )
      },
      {
        value: 2.5,
        label: <Typography variant="h2">😞</Typography>
      },
      {
        value: 5,
        label: <Typography variant="h2">😐</Typography>
      },
      {
        value: 7.5,
        label: <Typography variant="h2">😌</Typography>
      },
      {
        value: 10,
        label: (
          <Stack alignItems="center">
            <Typography variant="h2">😀</Typography>
            <Typography className="bottom" variant="h6" sx={{ fontSize: { xs: '0.5rem', sm: '0.75rem', md: '1rem', lg: '1.26rem' } }}>{agreeText}</Typography>
          </Stack>
        )
      }
    ]
      :
      [
        {
          value: 0,
          label: <Typography variant="h6">{disagreeText}</Typography>
        },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        {
          value: 10,
          label: <Typography variant="h6">{agreeText}</Typography>,
        }
      ])
  }, [showSmileys, i18n.language, t, variant])

  return (
    <Grid item sx={{ width: { xs: '80%', sm: '70%', md: '60%' } }}>
      <Stack direction="row" justifyContent="space-between"
        sx={{ marginLeft: '-5px', width: 'calc(100% + 15px)' }}>
        <Typography variant="h6">0</Typography>
        <Typography variant="h6">5</Typography>
        <Typography variant="h6">10</Typography>
      </Stack>
      <Slider
        value={typeof givenAnswer === "undefined" ? 5 : givenAnswer}
        min={0}
        max={10}
        marks={marks}
        step={1}
        valueLabelDisplay={"auto"}
        onChange={handleSliderChange}
        onKeyDown={preventKeyboardArrowSlide} //conflicts with page navigation
        onTouchStart={() => dispatch(setQuestionSliderIsDragging(true))} // prevent page navigation while dragging slider
        onTouchEnd={() => dispatch(setQuestionSliderIsDragging(false))} // prevent page navigation while dragging slider
        sx={{ height: '12px', width: '100%' }}
      />
    </Grid>
  )
}

export default DefaultAnswerOption
