import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      nl: {
        translation: {
          "Completely Disagree": "klopt niet",
          "Completely Agree": "klopt",
          "Score": "Uitkomst",
          "Question": "Vraag",
          "Answer": "Antwoord",
          "Click on a question to highlight it": "Je kunt op de onderstaande vragen klikken om deze te markeren. Gemarkeerde vragen vallen op, zo wordt het inzichtelijk welke vragen je graag wilt bespreken.",
          "Print results": "Print de uitkomsten",
          "Download results": "Download de uitkomsten",
          "Choose": "Kies",
          "*suitable for children from 12 years": "*vooral voor kinderen vanaf 12 jaar",
          "Read Out": "Lees voor",
        }
      },
      en: {
        translation: {
          "Completely Disagree": "completely disagree",
          "Completely Agree": "completely agree",
          "Score": "Score",
          "Question": "Question",
          "Answer": "Answer",
          "Click on a question to highlight it": "You can click on the questions to highlight them. Highlighted questions stand out, to easily point out questions you'd like to speak about.",
          "Print results": "Print the outcomes",
          "Download results": "Print de outcomes",
          "Choose": "Choose",
          "*suitable for children from 12 years": "*suitable for children from 12 years",
          "Read Out": "Read out",
        }
      },
      de: {
        translation: {
          "Completely Disagree": "stimme überhaupt nicht zu",
          "Completely Agree": "stimme voll zu",
          "Score": "Ergebnis",
          "Question": "Frage",
          "Answer": "Antwort",
          "Click on a question to highlight it": "Du kannst auf die folgenden Fragen klicken und markieren. Markierte Fragen werden angezeigt. So wird klar, über welche Fragen du sprechen möchtest",
          "Print results": "Ergebnis ausdrucken",
          "Download results": "Ergebnisse herunterladen",
          "Choose": "Wählen",
          "*suitable for children from 12 years": "*geeignet für Kinder ab 12 Jahren",
          "Read Out": "Vorlesen",
        }
      },
      fy: {
        translation: {
          "Completely Disagree": "kloppet net",
          "Completely Agree": "kloppet",
          "Score": "Útkomst",
          "Question": "Fraach",
          "Answer": "Antwurd",
          "Click on a question to highlight it": "Jo kinne op de ûndersteande fragen klikke om dy te markearjen. Markearre fragen falle op, sa wurdt dúdlik oanjûn hokker fragen jo graach besprekke wolle.",
          "Print results": "Printsje de útkomsten",
          "Download results": "Download de útkomsten",
          "Choose": "Kies",
          "*suitable for children from 12 years": "*benammen foar bern fan 12 en âlder",
          "Read Out": "Lês foar",
        }
      },
    },
    lng: "nl", // if you're using a language detector, do not define the lng option
    fallbackLng: "nl",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
