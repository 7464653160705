import { createTheme, Theme } from "@mui/material";
import { createContext, Dispatch, SetStateAction, useContext } from "react";

const defaultTheme = createTheme({
  typography: {
    fontFamily: "Open Sans,Arial,Helvetica,sans-serif",
    fontSize: 16,
    h1: {
      fontFamily: "Open Sans,Arial,Helvetica,sans-serif",
    }, h2: {
      fontFamily: "Open Sans,Arial,Helvetica,sans-serif",
    }, h3: {
      fontFamily: "Open Sans,Arial,Helvetica,sans-serif",
    }, h4: {
      fontFamily: "Open Sans,Arial,Helvetica,sans-serif",
    }, h5: {
      fontFamily: "Open Sans,Arial,Helvetica,sans-serif",
    }, h6: {
      fontFamily: "Open Sans,Arial,Helvetica,sans-serif",
    },
  },
  palette: {
    primary: {
      light: "rgb(228,3,46, 0.6)",
      main: "rgb(228,3,46)"
    },
    secondary: {
      main: "#F39A8B"
    },
    success: {
      main: "rgb(145, 162, 39)"
    },
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        markLabel: ({ theme }) => ({
          color: theme.palette.primary.main,
          fontSize: '2.5vw'
        }),
      }
    }
  }
});

export default defaultTheme;

export const simpleTheme: Theme = createTheme(
  {
    ...defaultTheme,
    palette: {
      primary: {
        main: "#00A4E2",
        light: "rgb(0, 164, 226, 0.6)",
        contrastText: "#fff"
      },
    }
  }
)

export const childTheme: Theme = createTheme(
  {
    ...defaultTheme,
    palette: {
      primary: {
        main: "#95C11F",
        light: "rgb(149, 193, 31, 0.6)",
        contrastText: "#fff"
      },
    }
  }
)

export const juniorTheme: Theme = createTheme(
  {
    ...defaultTheme,
    palette: {
      primary: {
        main: "#F59C00",
        light: "rgb(245, 156, 0, 0.6)",
        contrastText: "#fff"
      },
    }
  }
)

interface Props {
  theme?: Theme | undefined;
  setTheme?: Dispatch<SetStateAction<Theme>> | undefined;
}

export const ThemeContext = createContext<Props>({});

export const useMyTheme = () => useContext(ThemeContext);

