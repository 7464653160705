import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IQuestionnaire, IQuestionnaireQuestion, IQuestionnaireSection } from "../../model/IQuestionnaire";
import { fetchResults, QuestionnaireResults } from "./questionnaireAPI";

export type GivenAnswers = { [section: number]: number[] };

export interface QuestionnaireState {
  questionnaire?: IQuestionnaire;
  activeSection?: IQuestionnaireSection;
  activeQuestion?: IQuestionnaireQuestion;
  activeSectionIndex: number;
  activeQuestionIndex: number;
  givenAnswers: GivenAnswers;
  results: QuestionnaireResults
  questionSliderIsDragging: boolean;
  showIntroduction: boolean;
}

const initialState: QuestionnaireState = {
  activeSectionIndex: 0,
  activeQuestionIndex: 0,
  givenAnswers: {},
  results: {},
  questionSliderIsDragging: false,
  showIntroduction: false
};

export const fetchResultsAsync = createAsyncThunk(
  'questionnaire/fetchResults',
  async (givenAnswers: GivenAnswers) => {
    // The value we return becomes the `fulfilled` action payload
    return await fetchResults(givenAnswers);
  }
);

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    nextQuestion: (state) => {
      if (state.activeSection && state.questionnaire) {
        if (state.activeQuestionIndex < state.activeSection.questions.length - 1) {
          state.activeQuestionIndex += 1;
        } else {
          // check if it's he last question from last section
          if (state.activeSectionIndex !== state.questionnaire.sections.length - 1) {
            //start at the next section
            state.activeQuestionIndex = 0;
            state.activeSectionIndex += 1;
            state.activeSection = state.questionnaire?.sections[state.activeSectionIndex]
          }
        }
      }

      state.activeQuestion = state.activeSection?.questions[state.activeQuestionIndex]
    },
    previousQuestion: (state) => {
      if (state.activeQuestionIndex > 0) {
        state.activeQuestionIndex -= 1;
        state.activeSection = state.questionnaire?.sections[state.activeSectionIndex]
      } else {
        if (state.activeSectionIndex > 0) {
          state.activeSectionIndex -= 1;
          state.activeSection = state.questionnaire?.sections[state.activeSectionIndex]
          state.activeQuestionIndex = state.activeSection ? state.activeSection.questions.length - 1 : 0;
        }
      }
      state.activeQuestion = state.activeSection?.questions[state.activeQuestionIndex];
    },
    setQuestionnaire: (state, action: PayloadAction<IQuestionnaire>) => {
      if (!state.questionnaire || (state.questionnaire.variant !== action.payload.variant)) {

        state.questionnaire = action.payload;
        state.activeSectionIndex = 0;
        state.activeQuestionIndex = 0;
        state.activeSection = state.questionnaire.sections[0];
        state.activeQuestion = state.activeSection.questions[0];
        state.showIntroduction = !!state.questionnaire.introduction;
      }
    },
    setGivenAnswer: (state, action: PayloadAction<number>) => {
      let sectionAnswers = state.givenAnswers[state.activeSectionIndex];

      if (!sectionAnswers) {
        state.givenAnswers[state.activeSectionIndex] = [];
      }

      state.givenAnswers[state.activeSectionIndex][state.activeQuestionIndex] = action.payload
    },
    setQuestionSliderIsDragging: (state, action: PayloadAction<boolean>) => {
      state.questionSliderIsDragging = action.payload
    },
    setResults: (state, action: PayloadAction<number[]>) => {
      state.results = action.payload
    },
    setIntroductionShown: (state) => {
      state.showIntroduction = false
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchResultsAsync.fulfilled, (state, action) => {
        state.results = action.payload;
      })
      .addCase(fetchResultsAsync.rejected, (state) => {
        state.results = {};
      });
  }
});

export const {
  nextQuestion,
  previousQuestion,
  setQuestionnaire,
  setGivenAnswer,
  setQuestionSliderIsDragging,
  setResults,
  setIntroductionShown,
} = questionnaireSlice.actions;

export const selectActiveSection = (state: RootState) => state.questionnaire.activeSection;
export const selectActiveSectionIndex = (state: RootState) => state.questionnaire.activeSectionIndex;
export const selectActiveQuestion = (state: RootState) => state.questionnaire.activeQuestion;
export const selectShowAsteriskQuestionNote = (state: RootState) => state.questionnaire.activeQuestion?.text.trim().endsWith("*");
export const selectHasAsteriskQuestionNote = (state: RootState) => state.questionnaire.questionnaire?.sections.some(section => section.questions.some(question => question.text.trim().endsWith("*")));
export const selectActiveQuestionIndex = (state: RootState) => state.questionnaire.activeQuestionIndex;
export const selectQuestionnaire = (state: RootState) => state.questionnaire.questionnaire;
export const selectHideBackButton = (state: RootState) => state.questionnaire.activeSectionIndex === 0 && state.questionnaire.activeQuestionIndex === 0;
export const selectActiveVariant = (state: RootState) => state.questionnaire.questionnaire?.variant;
export const selectQuestionSliderIsDragging = (state: RootState) => state.questionnaire.questionSliderIsDragging;
export const selectIsLastQuestion = (state: RootState) => state.questionnaire.questionnaire &&
  state.questionnaire.activeSectionIndex === state.questionnaire.questionnaire.sections.length - 1 &&
  state.questionnaire.activeQuestionIndex === state.questionnaire.questionnaire.sections[state.questionnaire.activeSectionIndex].questions.length - 1;

export const selectResults = (state: RootState) => state.questionnaire.results
export const selectShowIntroduction = (state: RootState) => state.questionnaire.showIntroduction;
export const selectLanguageCode = (state: RootState) => state.questionnaire.questionnaire?.variant.includes("-") ? state.questionnaire.questionnaire?.variant.split("-")[1] : "nl";
export const selectGivenAnswers = (state: RootState) => state.questionnaire.givenAnswers;
export const selectActiveQuestionAnswered = (state: RootState) => typeof selectGivenAnswer(state) !== 'undefined';
export const selectGivenAnswer = (state: RootState) => {
  const sectionAnswers = state.questionnaire.givenAnswers[state.questionnaire.activeSectionIndex];
  if (sectionAnswers && typeof sectionAnswers[state.questionnaire.activeQuestionIndex] !== "undefined") {
    return sectionAnswers[state.questionnaire.activeQuestionIndex];
  }
  return undefined;
}

export const selectShowSmileys = (state: RootState) => state.questionnaire.questionnaire?.variant.startsWith("child") || state.questionnaire.questionnaire?.variant.startsWith("junior");

export default questionnaireSlice.reducer;
