import React, { useState } from "react";
import { questionnaireMap } from "../model/IQuestionnaire";
import { Grid, Chip, Tooltip } from "@mui/material";
import "./WelcomePage.css";
import VariantCard from "../component/Questionnaire/VariantCard";

const WelcomePage: React.FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("nl");

  const languageIcon = (lng: string) => {
    switch (lng) {
      case "nl":
        return <span role="img" aria-label="Nederlands">🇳🇱</span>;
      case "en":
        return <span role="img" aria-label="English">🇬🇧</span>;
      case "de":
        return <span role="img" aria-label="Deutsch">🇩🇪</span>;
      case "fy":
        return <img width="15px" src="img/emoji-flag-fy.png" alt="Frysk" />;
      default:
        return <span>{lng}</span>;
    }
  };

  const languageFull = (lng: string) => {
    switch (lng) {
      case "nl":
        return "Nederlands";
      case "en":
        return "English";
      case "de":
        return "Deutsch";
      case "fy":
        return "Frysk";
      default:
        return lng;
    }
  };

  // Group questionnaires by language
  const groupedByLanguage = Object.values(questionnaireMap).reduce((acc, questionnaireVariant) => {
    const { lng } = questionnaireVariant;
    if (!acc[lng]) {
      acc[lng] = [];
    }
    acc[lng].push(questionnaireVariant);
    return acc;
  }, {} as Record<string, typeof questionnaireMap[keyof typeof questionnaireMap][]>);

  return (
    <Grid sx={{ mt: 1, width: '100%' }} container spacing={5} justifyContent="left" alignItems="stretch">
      <Grid item xs={12}>
        {Object.keys(groupedByLanguage).map((lng) => (
          <Tooltip title={languageFull(lng)} placement="top">
            <Chip
              key={lng}
              variant="outlined"
              clickable
              color={lng === selectedLanguage ? "primary" : "default"}
              onClick={() => setSelectedLanguage(lng)}
              sx={{ marginRight: 1 }}
              label={languageIcon(lng)}
            />
          </Tooltip>
        ))}
      </Grid>
      {groupedByLanguage[selectedLanguage]?.map((questionnaireVariant) => (
        <VariantCard questionnaireVariant={questionnaireVariant} />
      ))}
    </Grid >
  );
};

export default WelcomePage;
