import React from 'react';

import { Route, Routes } from "react-router-dom";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { Stack } from "@mui/material";
import WelcomePage from "./page/WelcomePage";
import QuestionnairePage from "./page/QuestionnairePage";

const ResultsPage = React.lazy(() => import('./page/ResultsPage'))

const AppRouter = () => {

  return (
    <Routes>
      <Route path="/" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <WelcomePage />
        </React.Suspense>
      } />
      <Route path="/adults" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <QuestionnairePage variant="adults" />
        </React.Suspense>
      } />
      <Route path="/adults-en" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <QuestionnairePage variant="adults-en" />
        </React.Suspense>
      } />
      <Route path="/adults-de" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <QuestionnairePage variant="adults-de" />
        </React.Suspense>
      } />
      <Route path="/adults-fy" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <QuestionnairePage variant="adults-fy" />
        </React.Suspense>
      } />
      <Route path="/child" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <QuestionnairePage variant="child" />
        </React.Suspense>
      } />
      <Route path="/child-de" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <QuestionnairePage variant="child-de" />
        </React.Suspense>
      } />
      <Route path="/child-fy" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <QuestionnairePage variant="child-fy" />
        </React.Suspense>
      } />
      <Route path="/junior" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <QuestionnairePage variant="junior" />
        </React.Suspense>
      } />
      <Route path="/junior-de" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <QuestionnairePage variant="junior-de" />
        </React.Suspense>
      } />
      <Route path="/junior-fy" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <QuestionnairePage variant="junior-fy" />
        </React.Suspense>
      } />
      <Route path="/simple" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <QuestionnairePage variant="simple" />
        </React.Suspense>
      } />
      <Route path="/results" element={
        <React.Suspense fallback={<span>Loading...</span>}>
          <ResultsPage />
        </React.Suspense>
      } />
      <Route path="/health" element={<Stack mt={5} alignItems="center"><ThumbUpAltIcon /></Stack>} />
    </Routes>
  );
};

export default AppRouter;
