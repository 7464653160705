import { Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, ThemeProvider } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { IQuestionnaireVariant } from "../../model/IQuestionnaire";
import { t } from "i18next";

interface Props {
  questionnaireVariant: IQuestionnaireVariant;
}

const VariantCard: React.FC<Props> = ({ questionnaireVariant }) => {

  return (
    <ThemeProvider theme={questionnaireVariant.theme}>
      <Grid item xs={12} sm={6} md={3} key={questionnaireVariant.title}>
        <Card>
          <CardMedia
            sx={{ minHeight: 100, width: '100%', backgroundSize: 'contain' }}
            image={questionnaireVariant.image}
            title={questionnaireVariant.title}
          />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
            <Chip
              size="small"
              color="primary"
              sx={{ width: '150px', borderRadius: '5px', fontSize: '0.9rem' }}
              label={
                <Typography variant="caption" noWrap sx={{ fontSize: 'inherit', fontFamily: 'Dosis' }}>
                  {questionnaireVariant.tag}
                </Typography>
              }
            />
          </div>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {questionnaireVariant.description}
            </Typography>
          </CardContent>
          <CardActions sx={{ position: 'relative', bottom: '5px' }}>
            <Button fullWidth variant="contained" size="small" href={questionnaireVariant.href}>
              {t('Choose', { lng: questionnaireVariant.lng })}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </ThemeProvider>
  )
}

export default VariantCard;
