import { createTheme, Grid, ThemeProvider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useState } from "react";
import NavButton from "./NavButton";
import {
  fetchResultsAsync,
  nextQuestion,
  selectActiveQuestion,
  selectActiveQuestionAnswered,
  selectActiveSection,
  selectActiveVariant,
  selectGivenAnswers,
  selectIsLastQuestion,
  selectQuestionSliderIsDragging,
  selectShowAsteriskQuestionNote,
  selectShowSmileys,
  setGivenAnswer,
  setResults
} from "../../features/questionnaire/questionnaireSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import "./QuestionsViewer.css"
import DefaultAnswerOption from "./DefaultAnswerOption";
import SimpleAnswerOption from "./SimpleAnswerOption";
import { t } from "i18next";

const QuestionsViewer = () => {
  const navigate = useNavigate();
  const activeSection = useAppSelector(selectActiveSection);
  const activeQuestion = useAppSelector(selectActiveQuestion);
  const showAsteriskQuestionNote = useAppSelector(selectShowAsteriskQuestionNote);
  const givenAnswers = useAppSelector(selectGivenAnswers);
  const activeVariant = useAppSelector(selectActiveVariant);
  const activeQuestionAnswered = useAppSelector(selectActiveQuestionAnswered);
  const isLastQuestion = useAppSelector(selectIsLastQuestion);
  const showSmileys = useAppSelector(selectShowSmileys);
  const questionSliderIsDragging = useAppSelector(selectQuestionSliderIsDragging);

  const dispatch = useAppDispatch();
  const [sectionTheme, setSectionTheme] = useState(createTheme());
  const [questionOpacity, setQuestionOpacity] = useState(0);

  useEffect(() => {
    setQuestionOpacity(1);
  }, [])

  useEffect(() => {
    if (activeSection) {

      setSectionTheme(
        createTheme({
          palette: {
            primary: {
              main: activeSection?.color,
              contrastText: 'white'
            }
          },
          typography: {
            fontFamily: "Open Sans,Arial,Helvetica,sans-serif",
            button: {
              textTransform: "none"
            },
            h6: {
              color: activeSection.color,
              fontSize: '1.2625rem',
              [`@media screen and (max-width: 600px)`]: { fontSize: "0.6785rem" },
              [`@media screen and (max-width: 400px)`]: { fontSize: "0.5285rem" },
            },
            h4: {
              fontSize: '2.125rem',
              [`@media screen and (max-width: 600px)`]: { fontSize: "1.725rem" },
              [`@media screen and (max-width: 400px)`]: { fontSize: "1.425rem" },
            },
            h2: {
              fontSize: '3.625rem',
              [`@media screen and (max-width: 600px)`]: { fontSize: "2.725rem" },
              [`@media screen and (max-width: 400px)`]: { fontSize: "2.425rem" },
            },
          }
        })
      )
    }
  }, [activeSection]);

  const showResults = useCallback(() => {

    if (activeVariant === "simple") {
      dispatch(setResults(
        Object.values(givenAnswers).map((scores) => {
          const score = scores.at(-1);
          if (typeof score === 'undefined') {
            alert("Failed to calculate score");
            throw new Error("Failed to calculate score")
          }
          return score;
        })
      ));
    } else {
      dispatch(fetchResultsAsync(givenAnswers));
    }
    navigate("/results")
  }, [activeVariant, dispatch, givenAnswers, navigate])

  const next = useCallback(() => {
    if (!activeQuestionAnswered) return;

    if (isLastQuestion) {
      showResults()
    } else {
      setQuestionOpacity(0)

      setTimeout(() => {
        dispatch(nextQuestion())
        setQuestionOpacity(1)
      }, 200)
    }
  }, [dispatch, isLastQuestion, showResults, activeQuestionAnswered])

  useEffect(() => {

    const keyUpHandler = ({ key }: KeyboardEvent) => {
      if (key === "ArrowRight" || key === "ArrowUp") {
        isLastQuestion ? showResults() : next();
      } else if (+key >= 0) { //numeric
        if (!activeQuestion?.type || activeQuestion?.type === "DEFAULT") {
          dispatch(setGivenAnswer(+key))
        } else {
          if (+key <= 3 && +key > 0) { //simple options can be answered with 1-3
            dispatch(setGivenAnswer(+key))
          }
        }
      }
    }

    window.addEventListener("keyup", keyUpHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keyup", keyUpHandler);
    };
  }, [activeQuestion?.type, dispatch, isLastQuestion, next, showResults]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (!questionSliderIsDragging) next();
    }
  });

  const questionContainerHight = showSmileys ? '120px' : 'auto';

  return (
    <ThemeProvider theme={sectionTheme}>
      <Grid {...handlers}
        id="question-viewer-container"
        className={showSmileys ? 'smileys' : ''}
        container
        direction="column"
        justifyContent={{ xs: "space-between", md: "space-around" }}
        alignItems="center"
        sx={{ width: '100%', height: '100%', opacity: questionOpacity, transition: 'opacity 0.2s ease' }}
      >
        <Grid item sx={{ width: { xs: '98%', md: '70%' }, minHeight: questionContainerHight }}>
          <Typography color={activeSection?.color} variant="h4" textAlign="center">
            {activeQuestion && activeQuestion.text}
          </Typography>
          {showAsteriskQuestionNote && (
            <Typography color={activeSection?.color} variant="h6" textAlign="center">
              {!!showAsteriskQuestionNote && (t('*suitable for children from 12 years'))}
            </Typography>
          )}
        </Grid>
        {!activeQuestion?.type || activeQuestion.type === "DEFAULT" ?
          <DefaultAnswerOption />
          :
          <SimpleAnswerOption />
        }
      </Grid>
      <NavButton color={activeSection?.color} direction="next" onClick={next} />
    </ThemeProvider>
  )
}

export default QuestionsViewer;
