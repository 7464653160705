import defaultTheme, { childTheme, juniorTheme, simpleTheme } from "../theme/theme";
import { Theme } from "@mui/material";

export interface IQuestionnaire {
  variant: QuestionnaireVariant;
  title: string;
  introduction?: IQuestionnaireIntroduction;
  sections: IQuestionnaireSection[]
}

export interface IQuestionnaireIntroduction {
  title: string;
  content: string;
}

export interface IQuestionnaireSection {
  title: string;
  color: string;
  image: string;
  questions: IQuestionnaireQuestion[];
}

export interface IQuestionnaireQuestion {
  id: string;
  text: string;
  type?: "DEFAULT" | "SIMPLE" | "SIMPLE_REVERSED"
}

export interface IQuestionnaireVariant {
  theme: Theme;
  lng: 'nl' | 'en' | 'de' | 'fy';
  questionsLink: string;
  title: string;
  description: string;
  image: string;
  tag?: string;
  href: string;
}

export type QuestionnaireVariant = 'adults' | 'adults-en' | 'adults-de' | 'adults-fy' | 'simple' | 'child' | 'child-de' | 'child-fy' | 'junior' | 'junior-de' | 'junior-fy';

export const questionnaireMap: { [Property in QuestionnaireVariant]: IQuestionnaireVariant } = {

  "adults": {
    theme: defaultTheme,
    lng: 'nl',
    questionsLink: "/questions/adults.json",
    title: "VOLWASSENEN",
    description: "De complete versie voor volwassenen",
    image: "/logo.png",
    tag: "VOLWASSENEN",
    href: "/adults"
  },
  "simple": {
    theme: simpleTheme,
    lng: 'nl',
    questionsLink: "/questions/simple.json",
    title: "EENVOUDIG",
    description: "Vereenvoudigde variant",
    image: "/logo.png",
    tag: "EENVOUDIG",
    href: "/simple"
  },
  "child": {
    theme: childTheme,
    lng: 'nl',
    questionsLink: "/questions/child.json",
    title: "KIND 8-16 JAAR",
    description: "Vragenlijst voor kinderen",
    image: "/logo.png",
    tag: "KIND 8-16 JAAR",
    href: "/child"
  },
  "junior": {
    theme: juniorTheme,
    lng: 'nl',
    questionsLink: "/questions/junior.json",
    title: "JONGEREN 16-25 JAAR",
    description: "Vragenlijst voor jongeren",
    image: "/logo.png",
    tag: "JONGEREN 16-25 JAAR",
    href: "/junior"
  },
  "adults-en": {
    theme: defaultTheme,
    lng: 'en',
    questionsLink: "/questions/adults-en.json",
    title: "English",
    description: "The English questionnaire for adults",
    image: "/logo.png",
    tag: "ADULTS",
    href: "/adults-en"
  },
  "adults-de": {
    theme: defaultTheme,
    lng: 'de',
    questionsLink: "/questions/adults-de.json",
    title: "Erwachsene",
    description: "Der deutschsprachige Fragebogen für Erwachsene",
    image: "/logo-de.png",
    tag: "ERWACHSENE",
    href: "/adults-de"
  },
  "child-de": {
    theme: childTheme,
    lng: 'de',
    questionsLink: "/questions/child-de.json",
    title: "KIND 8-16 JAHRE",
    description: "Fragebogen für Kinder",
    image: "/logo-de.png",
    tag: "KIND 8-16 JAHRE",
    href: "/child-de"
  },
  "junior-de": {
    theme: juniorTheme,
    lng: 'de',
    questionsLink: "/questions/junior-de.json",
    title: "JUGEND 16-25 JAHRE",
    description: "Fragebogen für Jugendliche",
    image: "/logo-de.png",
    tag: "JUGEND 16-25 JAHRE",
    href: "/junior-de"
  },
  "adults-fy": {
    theme: defaultTheme,
    lng: 'fy',
    questionsLink: "/questions/adults-fy.json",
    title: "Folwoeksenen",
    description: "Fragelist Folwoeksenen",
    image: "/logo-fy.png",
    tag: "FOLWOEKSENEN",
    href: "/adults-fy"
  },
  "child-fy": {
    theme: childTheme,
    lng: 'fy',
    questionsLink: "/questions/child-fy.json",
    title: "Bern 8-16 Jier",
    description: "Fragelist Bern",
    image: "/logo-fy.png",
    tag: "BERN 8-16 JIER",
    href: "/child-fy"
  },
  "junior-fy": {
    theme: juniorTheme,
    lng: 'fy',
    questionsLink: "/questions/junior-fy.json",
    title: "Jongerein 16-25 Jier",
    description: "Fragelist Jongerein",
    image: "/logo-fy.png",
    tag: "JONGEREIN 16-25 JIER",
    href: "/junior-fy"
  },
}
