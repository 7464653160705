import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../app/hooks";
import { selectActiveQuestion, selectActiveVariant, selectLanguageCode } from "../../features/questionnaire/questionnaireSlice";
import { t } from "i18next";

const ReadSpeakerButton = () => {
  const activeVariant = useAppSelector(selectActiveVariant)
  const activeQuestion = useAppSelector(selectActiveQuestion)

  const languageCode = useAppSelector(selectLanguageCode)
  const [rsLangCode, setRsLangCode] = useState('nl_NL');

  useEffect(() => {
    switch (languageCode) {
      case 'nl':
        setRsLangCode('nl_NL');
        break;
      case 'en':
        setRsLangCode('en_UK');
        break;
      case 'de':
        setRsLangCode('de_DE');
        break;
      case 'fy':
        setRsLangCode('fy_NL');
        break;
      default:
        console.error('Unknown language code: ' + languageCode);
    }
  }, [languageCode])

  useEffect(() => {

    if (activeVariant === 'simple') {
      const rsLink = document.getElementById('readspeaker_link');
      if (rsLink) rsLink.click(); //autoplay for simple variant
    }

    return () => {
      // @ts-ignore
      window.rspkr && window.rspkr.ui && window.rspkr.ui.destroyActivePlayer();
    }
  }, [activeQuestion, activeVariant])

  return (
    <Box component="div" id="readspeaker_button1" className="rs_skip rsbtn rs_preserve" sx={{ minWidth: '375px' }}>
      {/* eslint-disable-next-line jsx-a11y/no-access-key */}
      <a rel="nofollow" id="readspeaker_link" className="rsbtn_play" accessKey="L" title="Laat de tekst voorlezen met ReadSpeaker webReader"
        href={`https://app-eu.readspeaker.com/cgi-bin/rsent?customerid=13779&amp;lang=${rsLangCode}&amp;readid=question-viewer-container&amp;url=${encodeURIComponent(document.location.href)}`}>
        <span className="rsbtn_left rsimg rspart"><span className="rsbtn_text"><span>{t('Read Out')}</span></span></span>
        <span className="rsbtn_right rsimg rsplay rspart"></span>
      </a>
    </Box>
  )
}

export default ReadSpeakerButton;
